html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  background: linear-gradient(
    180deg,
    var(--amplify-colors-background-primary) 0px,
    var(--amplify-colors-background-secondary) 0px
  );
}

table {
  padding: 0.5rem;
  text-align: left;
  border: var(--amplify-components-table-data-border-width) solid var(--amplify-components-table-data-border-color);
  border-bottom: 1px solid var(--amplify-components-table-data-border-color);
  border-collapse: var(--amplify-components-table-border-collapse);
}

th {
  padding: 0.5rem;
  border: 1px solid var(--amplify-components-table-data-border-color);
}

td {
  padding: 0.5rem;
  border: 1px solid var(--amplify-components-table-data-border-color);
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/* Amplify overrides */

.amplify-collection {
  width: 100% !important;
}
/* div.amplify-alert {
  border-radius: 20px;
} */
.amplify-select {
  min-width: 2rem !important;
  padding-inline-end: 1rem !important;
}

/* Component extras */

.noBar::-webkit-scrollbar {
  display: none;
}
.sideMenuIcon path {
  stroke: currentColor;
}
.logo {
  cursor: pointer!important;
}
.logo path {
  fill: var(--amplify-components-button-primary-background-color);
}
.list-title {
  cursor: pointer !important;
}
.icon-button {
  border-width: 1px !important;
  border-radius: var(--amplify-radii-large) !important;
  border-color: transparent !important;
}
.icon-button > * {
  font-weight: var(--amplify-components-button-font-weight) !important;
}
.icon-button .amplify-icon {
  fill: initial;
}
.icon-button path {
  stroke-width: 1px;
}
.icon-button:hover {
  border-width: 1px !important;
  border-radius: var(--amplify-radii-large) !important;
  background-color: var(--amplify-colors-background-tertiary) !important;
}

.custom-btn:hover {
  cursor: pointer;
 }

 .highlightQuestion {
  background-color: var(--amplify-colors-background-success);  
}

.highlightQuestion {
  background-color: var(--amplify-colors-background-error);
}

.floating {
  color: white;
  position: absolute;
  width: 50px;
  height: 30px;
  background: grey;
  padding: 4px;
  box-sizing: border-box;
  text-align: center;
  z-index: 1;
}

.arrow {
  position: absolute;
  width: 15px;
  height: 15px;
  background: grey;
  z-index: -1;
  pointer-events: none;
}



/* Container holding the tooltip text */
.tooltipsource {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* Optional: adds underline to indicate hoverable */
}

/* Tooltip text */
.tooltipsource .tooltiptextsource {
  visibility: hidden;
  width: 300px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -150px; /* Use half of the width (220/2 = 110), to center the tooltip */

  /* Optional: Adds an arrow pointing to the tooltip trigger */
  ::after {
      content: " ";
      position: absolute;
      top: 100%; /* At the bottom of the tooltip */
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: black transparent transparent transparent;
  }
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltipsource:hover .tooltiptextsource {
  visibility: visible;
}
